<template>
  <b-modal
    :id="id"
    hide-footer
    hide-header
    centered
  >
    <b-row class="justify-content-end">
      <b-img
        src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
        class="cursor-pointer m-50"
        @click="resetCondition(), $bvModal.hide(id)"
      />
    </b-row>
    <b-row class="justify-content-center mb-1">
      <h3 class="text-black">
        <strong>
          {{ title }}
        </strong>
      </h3>
    </b-row>
    <b-row class="justify-content-center mb-1">
      <b-col cols="11">
        <div class="text-[#828282] text-center">
          {{ descriptions }}
        </div>
      </b-col>
    </b-row>
    <form
      v-if="id === 'change-phone'"
      class="mb-1"
    >
      <b-row class="justify-content-center">
        <b-col
          cols="11"
          class="mb-1"
        >
          <label for="old-number">
            <strong class="text-black">Nomor HP Lama</strong>
          </label>
          <b-input-group>
            <template #prepend>
              <b-form-select
                :options="['+62']"
                :value="'+62'"
                style="background-color: #f4f4f4;"
              />
            </template>
            <b-form-input
              :placeholder="numberOld"
              type="number"
              style="background-color: #f4f4f4;"
              disabled
            />
          </b-input-group>
        </b-col>
        <b-col
          cols="11"
          class="mb-50"
        >
          <label for="new-number">
            <strong class="text-black">Nomor HP Baru</strong>
          </label>
          <phone-input
            :check-wa="true"
            :phone-used="true"
            :tooltip="false"
            @update:dataForParent="setCustomerPhone"
          />
          <small
            v-if="messageError !== ''"
            class="text-primary"
          >{{ messageError }}</small>
        </b-col>
        <b-col
          cols="11"
          class="mt-2"
        >
          <b-row class="justify-content-center">
            <b-col cols="6">
              <b-button
                :variant="requiredPhone || messageError !== '' ? 'outline-secondary' : 'outline-primary'"
                block
                :disabled="requiredPhone || messageError !== ''"
                @click="requestOTP('sms')"
              >
                <strong
                  :style="loadingNewSms ? 'visibility: hidden;' : ''"
                >OTP via SMS</strong>
                <lottie-animation
                  v-if="loadingNewSms"
                  path="animation/LoadingProcess.json"
                  class="loadingNextVerification"
                />
              </b-button>
            </b-col>
            <b-col cols="6">
              <b-button
                :variant="requiredPhone || isWhatsapp !== 'valid' || messageError !== '' ? 'secondary' : 'primary'"
                block
                :disabled="requiredPhone || isWhatsapp !== 'valid' || messageError !== ''"
                @click="requestOTP('whatsapp')"
              >
                <strong
                  :style="loadingNewWa ? 'visibility: hidden;' : ''"
                >OTP via Whatsapp</strong>
                <lottie-animation
                  v-if="loadingNewWa"
                  path="animation/LoadingProcess.json"
                  class="loadingNextVerification"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </form>
    <form
      v-else
      class="mb-1"
    >
      <b-row class="justify-content-center">
        <b-col
          cols="11"
          class="mb-1"
        >
          <label for="old-number">
            <strong class="text-black">Email Lama</strong>
          </label>
          <div
            class="d-flex align-items-center pl-1"
            style="background: #F4F4F4;
            border-radius: 8px; height: 3rem!important; border: 0;"
          >
            {{ censorEmail(email) }}
          </div>
        </b-col>
        <b-col
          cols="11"
          class="mb-50"
        >
          <label for="new-number">
            <strong class="text-black">Email Baru</strong>
          </label>
          <b-form-input
            id="new-number"
            v-model="newEmailItem"
            :style="messageError !== '' ? 'border: 1px solid #E31A1A; border-radius: 8px; height: 3rem!important; background: #FFEDED;' : 'border: 1px solid #E2E2E2; border-radius: 8px; height: 3rem!important;'"
            type="email"
            :formatter="(e) => String(e).substring(0, 50)"
            @blur="checkFormatEmailOnBlur"
            @input="checkFormatEmail"
          />
          <b-row
            v-if="messageError !== ''"
            class="my-50"
          >
            <small class="text-primary ml-1">{{ messageError }}</small>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          class="text-center mt-1"
        >
          <b-button
            :variant="messageError !== '' || newEmailItem === '' || loadingNewEmail || checkEmail ? 'secondary' : 'primary'"
            class="wrapper-loading-next-verification"
            :disabled="messageError !== '' || newEmailItem === '' || loadingNewEmail || checkEmail"
            @click="requestOTP('email')"
          >
            <strong :style="loadingNewEmail ? 'visibility: hidden;' : ''">Selanjutnya</strong>
            <lottie-animation
              v-if="loadingNewEmail"
              path="animation/LoadingProcess.json"
              class="loadingNextVerification"
            />
          </b-button>
        </b-col>
      </b-row>
    </form>
  </b-modal>
</template>
<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import phoneInput from '@/views/components/input/phoneInput.vue'
import secureLs from '@/libs/secureLs'

export default {
  components: {
    LottieAnimation,
    phoneInput,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    descriptions: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      default: '',
      required: false,
    },
    phoneNumber: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      loadingNewWa: false,
      loadingNewSms: false,
      newNumberItem: '',
      isWhatsapp: null,
      requiredPhone: true,
      checkWa: true,
      numberOld: this.censorPhone(this.phoneNumber),

      loadingNewEmail: false,
      newEmailItem: '',
      checkEmail: false,

      messageError: '',
    }
  },
  methods: {
    // global functions
    resetCondition() {
      this.loadingNewWa = false
      this.loadingNewSms = false
      this.loadingNewEmail = false
      this.isWhatsapp = ''
      this.newNumberItem = ''
      this.messageError = ''
      this.$bvModal.hide(this.id)
    },
    requestOTP(value) {
      const requestBy = value === 'email' ? 'email' : 'phone'
      const securityChange = secureLs.getItem(`securityChange${requestBy}`)
      const token = btoa(`${securityChange.securityChange.token}%${requestBy}`)
      let valueParent = null
      const body = {
        token,
        purpose: requestBy,
      }

      if (requestBy === 'phone') {
        Object.assign(body, {
          phone: this.newNumberItem,
          type_otp: value,
        })
        valueParent = this.newNumberItem
      } else {
        Object.assign(body, { email: this.newEmailItem })
        valueParent = this.newEmailItem
      }

      this.$http_new.post(`/auth/api/v1/otp/request-otp/${requestBy}`, body)
        .then(response => {
          const combinedData = {
            id: this.partnerId,
            email: this.newEmailItem,
            phone: this.newNumberItem,
            securityChange: response.data.data,
          }
          secureLs.setItem(`securityChange${requestBy}`, JSON.stringify(combinedData))
          this.$emit('on-confirm-button', { valueParent, token })
          this.resetCondition()
        }).catch(err => {
          let message = ''
          if (err.response.data.data === 'reached limit request otp') {
            message = 'Akunmu telah mencapai batas permintaan OTP, silahkan coba kembali 24 jam dari permintaan OTP terakhir'
          } else {
            message = 'Gagal kirim permintaan OTP, silahkan coba lagi'
          }
          this.loading = false
          this.$toast_error({ message: `${message}` })
        })
    },

    // functions change phone
    censorPhone(phone) {
      if (phone !== '') {
        return '*'.repeat(phone.length) + phone.slice(-3)
      }
      return phone
    },
    setCustomerPhone(newVal) {
      this.messageError = ''
      this.newNumberItem = newVal.customerPhone
      this.requiredPhone = newVal.requireCustomerPhone || newVal.messageError !== ''
      this.isWhatsapp = newVal.isWhatsapp

      let formatPhoneNumber = ''

      if (this.phoneNumber.startsWith('0')) {
        formatPhoneNumber = this.phoneNumber.substring(1)
      } else {
        formatPhoneNumber = this.phoneNumber
      }

      if (!this.requiredPhone && newVal.messageError === '') {
        if (this.newNumberItem === formatPhoneNumber) {
          this.messageError = '*Nomor Hp tidak boleh sama dengan sebelumnya'
        }
      }
    },

    // functions change email
    censorEmail(value) {
      if (value !== '') {
        const email = value.split('@')
        const realEmail = email[0].slice(0, 3) + '*'.repeat(email[0].length)
        const domainEmail = email[1].slice(0, 1) + '*'.repeat(email[1].length - 3) + email[1].slice(-3)
        return `${realEmail}@${domainEmail}`
      }
      return ''
    },
    checkFormatEmailOnBlur() {
      if (this.newEmailItem === this.email) {
        this.messageError = '*Email tidak boleh sama dengan sebelumnya'
      } else if (String(this.newEmailItem)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )) {
        this.loadingNewEmail = true
        this.checkEmailUsed()
      } else {
        this.messageError = '*Format Email belum benar'
      }
    },
    checkFormatEmail() {
      this.checkEmail = true
      if (!this.newEmailItem.match(/^[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/)) {
        this.messageError = '*Format Email belum benar'
      } else if (this.newEmailItem === this.email) {
        this.messageError = '*Email tidak boleh sama dengan sebelumnya'
      } else {
        this.messageError = ''
      }
    },
    checkEmailUsed: _.debounce(async function () {
      await this.$http.get(`/activity/user/check/email?email=${this.newEmailItem}`)
        .then(res => {
          this.messageError = ''
          this.loadingNewEmail = false
          this.checkEmail = false
        })
        .catch(err => {
          if (err.response.data.code === 1001) {
            this.messageError = '*Email sudah digunakan orang lain'
          }
          this.loadingNewEmail = false
        })
    }, 1000),
  },
}
</script>
<style scoped>
.loadingNextVerification {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px!important;
  height: auto!important;
}
.wrapper-loading-next-verification {
  position: relative;
}
</style>
